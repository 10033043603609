var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-card',{attrs:{"title":_vm.$t('Edit Tcompany')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.name ?false:null,"placeholder":_vm.$t('name')},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}}),(_vm.errors.name)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.name[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('name')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.phone ?false:null,"placeholder":_vm.$t('phone')},model:{value:(_vm.data.phone),callback:function ($$v) {_vm.$set(_vm.data, "phone", $$v)},expression:"data.phone"}}),(_vm.errors.phone)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.phone[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('phone')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.adress ?false:null,"placeholder":_vm.$t('adress')},model:{value:(_vm.data.adress),callback:function ($$v) {_vm.$set(_vm.data, "adress", $$v)},expression:"data.adress"}}),(_vm.errors.adress)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.adress[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('adress')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.maplan ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('maplan'),"state":_vm.errors.maplan ?false:null},model:{value:(_vm.data.maplan),callback:function ($$v) {_vm.$set(_vm.data, "maplan", $$v)},expression:"data.maplan"}}),(_vm.errors.maplan)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.maplan[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('maplan')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('cleave',{staticClass:"form-control mt-2",class:_vm.errors.maplat ?'is-invalid':null,attrs:{"raw":false,"options":{
                  numeral: true,
                  numeralThousandsGroupStyle: 'none',
                  numeralDecimalScale: 0,
                },"placeholder":_vm.$t('maplat'),"state":_vm.errors.maplat ?false:null},model:{value:(_vm.data.maplat),callback:function ($$v) {_vm.$set(_vm.data, "maplat", $$v)},expression:"data.maplat"}}),(_vm.errors.maplat)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.maplat[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('maplat')))])],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{staticClass:"mt-2",attrs:{"state":_vm.errors.email ?false:null,"placeholder":_vm.$t('email')},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}}),(_vm.errors.email)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.email[0]))]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.$t('email')))])],1)])],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"w-100 mb-1",attrs:{"variant":"gradient-success"},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")]),_c('b-button',{staticClass:"w-100",attrs:{"variant":"gradient-danger"},on:{"click":function($event){_vm.data={},_vm.errors=[]}}},[_vm._v(" "+_vm._s(_vm.$t('Reset Form'))+" ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }